var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message ? _c('div', [_vm.showReplyModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1200
    },
    on: {
      "close": function close($event) {
        _vm.showReplyModal = false;
      }
    }
  }, [_c('CourseSendMessageModal', {
    attrs: {
      "replyMessageId": _vm.messageId,
      "replyMessageSubject": _vm.message.subject,
      "replyMessageRecipient": _vm.message.senderName,
      "headline": "Svar p\xE5: ".concat(_vm.message.subject),
      "modalType": _vm.ModalType.Add,
      "possibleRecipients": _vm.participants
    },
    on: {
      "close": function close($event) {
        _vm.showReplyModal = false;
      }
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.goBackToInbox
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.message.isRead ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "mark-unread"
          },
          on: {
            "click": _vm.markUnread
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email")]), _vm._v(" Marker som ulest og lukk ")], 1) : _vm._e(), !_vm.message.isRead ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "mark-read"
          },
          on: {
            "click": _vm.markRead
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-open")]), _vm._v(" Marker som lest og lukk ")], 1) : _vm._e(), _vm.participants && !_vm.message.sentFromKursAdmin ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "reply-message"
          },
          on: {
            "click": _vm.answerEmail
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-edit")]), _vm._v(" Svar på denne meldingen ")], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 2694487632)
  }, [[_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.message.sentFromKursAdmin ? "Til:" : "Fra:") + " "), _c('span', {
    attrs: {
      "data-cy": "message-from"
    }
  }, [_vm._v(_vm._s(_vm.message.sentFromKursAdmin ? _vm.message.recipientName : _vm.message.senderName))])]), _c('v-card-title', {
    staticClass: "py-0",
    attrs: {
      "data-cy": "message-subject",
      "data-private": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.message.subject) + " ")]), _c('v-card-text', {
    attrs: {
      "data-cy": "message-text",
      "data-private": ""
    }
  }, [_c('viewer', {
    staticClass: "pb-4",
    attrs: {
      "initialValue": _vm.message.text
    }
  }), _vm.hasAttatchment > 0 ? _c('v-divider') : _vm._e(), _vm.hasAttatchment > 0 ? _c('div', {
    staticClass: "pt-4 d-flex"
  }, [_c('h3', {
    staticClass: "pt-1"
  }, [_vm._v("Vedlegg:")]), _vm._l(_vm.message.attachments, function (attachment) {
    return _c('v-chip', {
      key: attachment.fileId,
      staticClass: "mx-2",
      on: {
        "click": function click($event) {
          return _vm.showFilePreviewModal(attachment);
        }
      }
    }, [_vm._v(" " + _vm._s(attachment.fileName) + " ")]);
  })], 2) : _vm._e()], 1), _vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-subtitle', {
    attrs: {
      "data-private": ""
    }
  }, [_vm._v(_vm._s(_vm.formattedDate))])], 1)], 1)]], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }